<script setup lang="ts">
import type { NovaBottomTabBar } from '#components'

const route = useRoute()
const layoutStore = useLayoutStore()
const { responsive, bottomTabBarRect } = storeToRefs(layoutStore)
const showBottomTabBar = computed(() => {
  if (String(route.name).includes('callback-')) {
    return false
  }

  switch (responsive.value) {
    case 'mobile':
      return true
    case 'tablet':
    case 'desktop':
    default:
      return false
  }
})

const updateResponsive = () => {
  const responsive = useGetResponsive()
  layoutStore.updateResponsive(responsive)
}

onMounted(() => {
  if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
    layoutStore.updateTouchDevice(true)
  }
  updateResponsive()
  window.addEventListener('resize', updateResponsive)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateResponsive)
})
</script>

<template>
  <div
    class="wrapper"
    :style="{
      paddingBottom: `${bottomTabBarRect?.height || 0}px`,
    }"
  >
    <slot />
    <NovaBottomTabBar v-if="showBottomTabBar" />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  background-color: $color-neutral-light-4;
}
</style>

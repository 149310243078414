<script setup lang="ts">
import { v4 } from 'uuid'
import { ROUTES } from '@configs'

import TabHomeDefault from '@assets/images/bottom-tab-home-default.svg?url'
import TabHomeActive from '@assets/images/bottom-tab-home-active.svg?url'
import TabFeedDefault from '@assets/images/bottom-tab-feed-default.svg?url'
import TabFeedActive from '@assets/images/bottom-tab-feed-active.svg?url'
import TabSearchDefault from '@assets/images/bottom-tab-search-default.svg?url'
import TabSearchActive from '@assets/images/bottom-tab-search-active.svg?url'
import TabUserDefault from '@assets/images/bottom-tab-user-default.svg?url'
import { LayerType } from '@store'

const route = useRoute()
const { t } = useI18n()
const { gtEvent } = useGoogleTag()
const { openMembershipServicePopup } = useMembershipProcess()
const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const postStore = usePostStore()
const { posts } = storeToRefs(postStore)
const layoutStore = useLayoutStore()
const { responsive, layers } = storeToRefs(layoutStore)
const novaBottomTabBarRef = ref<HTMLElement>()
const isPortraitLoadSuccess = ref(false)

const isSearchPanelOpen = computed(() =>
  layers.value.includes(LayerType.SEARCH_PANEL)
)
const curRouteName = computed(() => useGetRouteName(String(route.name)))
const isHomeActive = computed(
  () => curRouteName.value === ROUTES.HOME.name && !isSearchPanelOpen.value
)
const isFeedActive = computed(
  () => curRouteName.value === ROUTES.FEED.name && !isSearchPanelOpen.value
)
const isKeyboardUp = ref(false)
// const isSearchActive = computed(() => )

// 사용자 포트레이트 로드 성공시 모델 업데이트
const handleOnPortraitLoadSuccess = () => {
  isPortraitLoadSuccess.value = true
}

// 탭 이벤트: 홈으로 가기
const handleOnClickGoHome = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '홈 가기',
    eventLabel: t('navigations.home'),
    eventSlot: '모바일 하단 탭',
    eventI18nAddr: useKoreanTranslation('navigations.home'),
    eventComponent: 'NovaBottomTabBar',
  })
  layoutStore['layer:closeAll']()
  useNavigations({ url: ROUTES.HOME.path })
}

// 탭 이벤트: 피드 가기
const handleOnClickGoFeed = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '피드 가기',
    eventLabel: t('navigations.feed'),
    eventSlot: '모바일 하단 탭',
    eventI18nAddr: useKoreanTranslation('navigations.feed'),
    eventComponent: 'NovaBottomTabBar',
  })

  layoutStore['layer:closeAll']()

  const query = useGetFeedQuery()
  useNavigations({
    url: ROUTES.FEED.path,
    query,
    type:
      posts.value.items.length && posts.value.payload.cmtyNttCtgrySn
        ? 'replace'
        : 'push',
  })
}

// 탭 이벤트: 포스트 작성
const handleOnClickOpenPostCreateGate = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '포스트 작성 게이트 열기',
    eventLabel: t('createPost'),
    eventSlot: '모바일 하단 탭',
    eventI18nAddr: useKoreanTranslation('createPost'),
    eventComponent: 'NovaBottomTabBar',
  })
  layoutStore['layer:closeAll']()
}

// 탭 이벤트: 검색 패널 열기
const handleOnClickSearch = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '검색 패널 열기',
    eventLabel: '',
    eventSlot: '모바일 하단 탭',
    eventI18nAddr: '',
    eventComponent: 'NovaBottomTabBar',
  })

  const prevOpenSearchPanel = layers.value.includes(LayerType.SEARCH_PANEL)
  layoutStore['layer:closeAll']()
  layoutStore[prevOpenSearchPanel ? 'layer:close' : 'layer:open'](
    LayerType.SEARCH_PANEL
  )
}

// 탭 이벤트: 사용자 홈 가기(로그인 요구)
const handleOnClickPortrait = () => {
  const messageAddress = user.value
    ? user.value.cmtyUserSeCode === 'C'
      ? 'linkCreatorHome'
      : 'linkMyHome'
    : 'sign.in'

  const gtEventPayload = {
    eventLabel: t(messageAddress),
    eventI18nAddr: useKoreanTranslation(messageAddress),
  }

  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '사용자 홈 가기',
    eventSlot: '모바일 하단 탭',
    ...gtEventPayload,
    eventComponent: 'NovaBottomTabBar',
  })

  layoutStore['layer:closeAll']()

  switch (messageAddress) {
    case 'linkCreatorHome':
      useNavigations({
        url: useRoutePathIdChange(ROUTES.CREATOR.path, {
          id: `@${user.value!.creatorLink}`,
        }),
      })
      break
    case 'linkMyHome':
      useNavigations({
        url: useRoutePathIdChange(ROUTES.USER.path, {
          id: `@${user.value!.userNcnm}`,
        }),
      })
      break
    case 'sign.in':
    default:
      openMembershipServicePopup.signin()
      break
  }
}

// const handleOnFormTagFocus = (evt: FocusEvent) => {
//   const keyboardUpTags = [
//     'input[type="text"]',
//     'input[type="password"]',
//     'input[type="email"]',
//     'input[type="tel"]',
//     'input[type="number"]',
//     'input[type="search"]',
//     'input[type="url"]',
//     'input[type="url"]',
//     'input[type="date"]',
//     'input[type="datetime-local"]',
//     'textarea',
//     '[contenteditable="true"]',
//   ]
//
//   if (!(evt.target as Element).matches(keyboardUpTags.join(', '))) return
//
//   // console.log('Focused element:', evt.target)
//   if (evt.type === 'focus') isKeyboardUp.value = true
//   if (evt.type === 'blur') isKeyboardUp.value = false
// }

const handleOnViewPortResize = () => {
  const rect =
    novaBottomTabBarRef.value && responsive.value === 'mobile'
      ? novaBottomTabBarRef.value.getBoundingClientRect()
      : null
  layoutStore.updateBottomTabBarRect(rect)
}

// 높이 실시간 변경 감지
onMounted(() => {
  handleOnViewPortResize()
  window.visualViewport?.addEventListener('resize', handleOnViewPortResize)

  // document.addEventListener('focus', handleOnFormTagFocus, true)
  // document.addEventListener('blur', handleOnFormTagFocus, true)
})

onUnmounted(() => {
  window.visualViewport?.removeEventListener('resize', handleOnViewPortResize)

  // document.removeEventListener('focus', handleOnFormTagFocus, true)
  // document.removeEventListener('blur', handleOnFormTagFocus, true)
})
</script>

<template>
  <nav
    ref="novaBottomTabBarRef"
    :class="['nova-bottom-tab-bar', { hide: isKeyboardUp }]"
  >
    <div class="inner">
      <div class="tab-item">
        <button
          type="button"
          class="tab-item-button"
          @click="handleOnClickGoHome"
        >
          <img :src="isHomeActive ? TabHomeActive : TabHomeDefault" alt="" />
        </button>
      </div>

      <div class="tab-item">
        <button
          type="button"
          class="tab-item-button"
          @click="handleOnClickGoFeed"
        >
          <img :src="isFeedActive ? TabFeedActive : TabFeedDefault" alt="" />
        </button>
      </div>

      <div class="tab-item">
        <NovaBoxPostCreateGate
          :popover-position="'top-middle'"
          :on-before-open="handleOnClickOpenPostCreateGate"
        >
          <template #button>
            <NovaCreatePostButton class="highlight" />
          </template>
        </NovaBoxPostCreateGate>
      </div>

      <div class="tab-item">
        <button
          type="button"
          class="tab-item-button"
          @click="handleOnClickSearch"
        >
          <img
            :src="
              layers.includes(LayerType.SEARCH_PANEL)
                ? TabSearchActive
                : TabSearchDefault
            "
            alt=""
          />
        </button>
      </div>

      <div class="tab-item">
        <button
          type="button"
          :class="[
            'tab-item-button',
            'tab-user-button',
            { 'portrait-loaded': isPortraitLoadSuccess },
          ]"
          @click="handleOnClickPortrait"
        >
          <ClientOnly>
            <NovaImageContainer
              v-if="user?.userProflUrl"
              :key="user?.userProflUrl || v4()"
              :image-url="user?.userProflUrl"
              :ratio="'1:1'"
              :empty-type="'none'"
              class="user-portrait toggle-panel-button"
              @on-img-load="handleOnPortraitLoadSuccess"
            />

            <img v-else :src="TabUserDefault" alt="" />
          </ClientOnly>
        </button>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
.nova-bottom-tab-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: $color-white;
  box-shadow: 0 0 10px 0 #00000014;
  z-index: $z-index-bottom-tab-bar;
  padding-bottom: env(safe-area-inset-bottom);

  &.hide {
    display: none;
  }

  > .inner {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    -webkit-grid-template-columns: repeat(5, minmax(0, 1fr));
    width: 100%;
    max-width: 768px;
  }

  .tab-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    min-width: 54px;

    .tab-item-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      font-size: 0;

      &.tab-user-button {
        &.portrait-loaded {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          overflow: hidden;
        }
      }
    }

    .highlight {
      margin-top: -10px;
    }
  }
}
</style>
